<template>
  <div class="container">
    <div class="text-center">
      <img class="mt-4 mb-4" alt="" src="../assets/images/fireworks.svg" style="height: 100;" />

      <h3 class="mb-4">Congrats and welcome to {{ communityName }}</h3>

      <p>
        Your email <strong>{{ email }}</strong> was registered succesfully.
      </p>

      <p class="mb-0">You will soon receive more information in your inbox.</p>
      <p class="mb-4"><i>Can't find the email? Please check your junk folder.</i></p>

      <div class="d-flex justify-content-evenly gap-5 mt-5">
        <a href="https://apps.apple.com/us/app/awaio/id1527744468">
          <img alt="Download on App Store" src="../assets/images/apple-app-store-badge.svg" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=se.awaio.awaioapp">
          <img alt="Download on Google Play" src="../assets/images/google-play-badge.svg" />
        </a>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-5 mb-3">
      <a href="https://awaio.com">Visit awaio.com</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
  props: {
    email: null,
    communityName: null,
  },
  setup() {},
};
</script>

<style></style>
