<template>
  <div class="container">
    <div class="d-flex justify-content-center mt-3 mb-3">
      <AwaioLogo />
    </div>

    <div v-if="progress == 'loading'" class="m-5">
      <div class="spinner-grow text-primary m-5" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div v-if="progress == 'register'">
      <CommunityImage :communityImage="communityImage" />

      <Register @signUp="handleSignUp" :communityName="communityName" />
    </div>

    <div v-else-if="progress == 'processing'">
      <div class="spinner-grow text-primary m-5" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">processing...</span>
      </div>
    </div>

    <div v-else-if="progress == 'success'">
      <Success :email="email" :communityName="communityName"/>
    </div>

    <div v-else-if="progress == 'failed'">
      <Failed :email="email" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
// import { getInvitationData, registerUser } from "../firebase/firebase"; //registerUser
import AwaioLogo from "../components/AwaioLogo.vue";
import Success from "../components/Success.vue";
import Register from "../components/Register.vue";
import Failed from "../components/Failed.vue";
import Signup from "../firebase/Signup";
import CommunityImage from "../components/CommunityImage.vue";

export default {
  name: "Welcome",
  components: {
    AwaioLogo,
    Failed,
    Register,
    Success,
    CommunityImage
},
  created() {
    this.$nextTick(() => {
      // When created, load invitation data
      this.getInvitationData();
    });
  },
  setup() {
    const signup = new Signup();
    const progress = ref("loading");
    const communityName = ref("");
    const communityImage = ref(null);
    const route = useRoute();
    const email = ref("");

    const handleSignUp = async (data) => {
      progress.value = "processing";
      let response = await signup.registerUser(route.query.id, data.email, data.accepted);
      if (response != null) {
        email.value = data.email;
        progress.value = "success";
      } else {
        progress.value = "failed";
      }
    };

    const getInvitationData = async () => {
      let response = await signup.getInvitationData(route.query.id);

      if (response != null) {
        communityName.value = response.data.name;
        if (response.data.photo != null) {
          communityImage.value = response.data.photo;
        }
        progress.value = "register";
      } else {
        progress.value = "failed";
      }
    };

    return { 
      communityName,
      communityImage, 
      handleSignUp, 
      progress, 
      email, 
      getInvitationData
     };
  },
};
</script>