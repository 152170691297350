<template>
      <div v-if="communityImage != null">
        <!-- Community image -->
        <div class="d-flex justify-content-center mb-3">
          <img class="rounded " alt="" :src="communityImage" style="height: 300px;"/>
        </div>
      </div>
      
      <!-- If no image display awaio default image -->
      <div v-if="communityImage == null">
        <div class="d-flex justify-content-center mb-3">
          <img class="rounded " alt="" src="../assets/images/early-access.jpg" style="height: 300px;" />
        </div>
      </div>
</template>

<script>

export default {
  name: "CommunityImage",
  props: {
    communityImage: null,
  }
};
</script>
