import firebase from "firebase/app";
import "firebase/functions";
import config from "../config/config";

class Firebase {
  constructor() {
    this.initialized = false;
  }
  /**
   * Returns when firebase is initialized
   * @returns nothing
   */
  async _firebaseReady() {
    if (this.initialized == true) {
      return;
    } else {
      await fetch("/__/firebase/init.json").then(async (response) => {
        this.firebaseApp = firebase.initializeApp(await response.json());
        this.functions = this.firebaseApp.functions(config.regions);
        this.initialized = true;
        return;
      });
    }
  }

  /**
   * Function to call firebase function
   * @param {*} name Function name to call
   * @param {*} data Data to send to function
   * @returns
   */
  async callFunction(name, data) {
    await this._firebaseReady();

    let request = this.functions.httpsCallable(name);
    try {
      const response = await request(data);
      return response;
    } catch (e) {
      console.error(e.code, e.message);
      return null;
    }
  }
}

export default Firebase;
