import Firebase from "./firebase";
import config from "../config/config";

class Signup extends Firebase {
  constructor() {
    super();
  }

  /**
   * Get data from an invitation code
   * @param {*} invitationCode
   * @returns
   */
  async getInvitationData(invitationCode) {
    const data = {
      type: "request",
      invitationCode: invitationCode,
    };

    return await this.callFunction(config.functions.signup, data);
  }

  /**
   * Register new invitation
   * @param {*} invitationCode
   * @param {*} email
   * @param {*} accepted
   * @returns signup result
   */
  async registerUser(invitationCode, email, accepted) {
    const data = {
      type: "register",
      invitationCode: invitationCode,
      email: email,
      accepted: accepted,
    };

    return await this.callFunction(config.functions.signup, data);
  }
}

export default Signup;
